/* You can add global styles to this file, and also import other style files */
@import "globals";
@import "../../node_modules/bootstrap/scss/bootstrap";
@import "bootstrap-reboot";
@import "breakpoints";

@import "headers";
@import "footers";
@import "src/app/shared/components/filter-business-partner/filter-business-partner.component";
@import "src/app/features/orders/features/presentacion/orders-list/orders-list.component";
@import "src/app/features/orders/features/presentacion/warehouse-order-preparation/warehouse-order-preparation.component";
@import "src/app/features/orders/features/presentacion/orders-wanings/orders-warnings.component";
@import "src/app/features/product-offer/presentation/list-offer-for-request/list-offer-for-request.component";
@import "src/app/features/marketplace/features/presentation/dashboard-analytics/dashboard-analytics.component";
@import "src/app/features/request/presentation/active-request/active-request.component";
@import "src/app/features/request/presentation/my-request/my-request.component";

/*Here  import all the components sass files to be compiled*/
@import "popup";



/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

