.approval-popup-container {
  display: flex;
  align-items: center;
}

.approval-popup-icon {
  flex: 0 0 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.approval-popup-icon img {
  max-width: 100%;
  max-height: 100%;
}

.approval-popup-content {
  flex: 1;
  padding-left: 20px;
}

.approval-popup-content p {
  text-align: justify;
}

.approval-popup-question {
  font-weight: bold;
  color: #005eac;
}

.approval-popup-title {
  text-align: left;
  color: #29aae3;
  margin-bottom: 20px;
  margin-top: 20px;
}

.sweet-customized-actions .sweet-customized-confirm-button,
.sweet-customized-actions .sweet-customized-deny-button {
  width: 150px; /* Set a fixed width for the buttons */
  height: 40px; /* Set a fixed height for the buttons */
  border-radius: 20px; /* Rounded borders */
  padding: 10px 20px; /* More elongated appearance */
  display: inline-block;
  text-align: center;
}
