@import "src/styles/globals";
@import "src/styles/breakpoints";

.marketplace-dashboard-container {
  width: 90%;
  margin: 5rem auto;
  @include min-height;

  @media only screen and (min-width: 1400px) {
    max-width: 1500px;
  }

  &--title {
    border: solid 1px;
    width: 100%;
    @include align-items-center;
    background: $color-secondary;
    font-family: PoppingSemiBold, sans-serif;
    color: $color-sweet;
    border-radius: 8px;
    height: 48px;

    &:hover {
      background: $color-primary;
      cursor: pointer;
    }

    span {
      font-size: 1rem;
      text-align: start;
      margin-left: 2rem;
      @include align-items-center;
    }

    h2 {
      width: 450px;
      margin: 0 auto;
      font-size: 1.5rem;

      @include media(desktop-m) {
        width: 550px;
        font-size: 2rem;
      }
    }
  }


  &--card {
    width: 100%;
    background: #FFFFFF 0 0 no-repeat padding-box;
    border-radius: 32px;
    margin-top: .5rem;

    mat-card-content{
      font-size: 14px;
      padding: 0 16px !important;
    }
    &__form {
      width: 100%;

      &--field{
        margin-top: 1rem;
        mat-label{
          font-family: PoppingSemiBold, sans-serif;
          color: $color-primary;
          font-size: .9rem;
        }

        mat-form-field {
          width: 100%;
          height: 10px;
          font-family: PoppingLight, sans-serif;


          .mat-form-field-appearance-outline .mat-form-field-outline {
            color: $color-secondary !important;
          }
          .mat-mdc-text-field-wrapper{
            height: 40px !important;
          }
          .mat-mdc-form-field-infix{
            max-height: 20px !important;
            padding-top: 5px !important;
            padding-bottom: 5px !important;
          }
          .mat-mdc-form-field-flex{
            height: 40px !important;
          }

          .mat-mdc-option{
            font-size: 10px !important;
          }


        }
        &--option{
          font-family: PoppingLight, sans-serif;
          font-size: .9rem;
        }
      }

      &--actions {
        width: 100%;
        @include align-items-end;

        button {
          font-family: PoppingSemiBold, sans-serif;
          font-size: .7rem;
          margin: 2rem .5rem;
          color: $color-sweet;

          &:first-child {
            color: $color-primary;
          }

          width: 130px;
          height: 25px;
          @include media(desktop-m) {
            width: 150px;
            height: 30px;
            font-size: .9rem;
          }
        }

      }


    }
  }
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: $color-secondary !important;
}

.marketplace-dashboard-container--card {
  background-color: white;
}

#dashboard-market {
  background-color: white;
}
