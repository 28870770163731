@import "src/styles/globals";
@import "src/styles/breakpoints";

@include close_button;

.list-so-warnings-container {
  width: 100%;
  @include min-height;
  overflow-x: hidden; /* Remove horizontal scroll */
  margin: 3rem 0;


  &--title {
    margin: 0 2rem;
    max-width: 90%;
    border: solid 1px;
    @media only screen and (min-width: 1300px) {
      @include max-width;
    }
    @include align-items-center;
    background: $color-secondary;
    font-family: PoppingSemiBold, sans-serif;
    color: $color-sweet;
    border-radius: 8px;
    height: 48px;

    &:hover {
      background: $color-primary;
      cursor: pointer;
    }

    span {
      font-size: 1rem;
      text-align: start;
      margin-left: 2rem;
      @include align-items-center;
    }

    h2 {
      width: 450px;
      margin: 0 auto;
      font-size: 1.5rem;

      @include media(desktop-m) {
        width: 550px;
        font-size: 2rem;
      }
    }
  }


  &--card {
    width: 100%;
    background: #FFFFFF 0 0 no-repeat padding-box;
    box-shadow: 0 7px 9px #00000024;
    border-radius: 32px;
    overflow-x: hidden; /* Remove horizontal scroll */

    &__form {

      background: #FFFFFF 0 0 no-repeat padding-box;
      box-shadow: 0 7px 9px #00000024;
      border-radius: 32px;
      width: 90%;
      margin: 1rem 2rem;
      @media only screen and (min-width: 1300px) {
        @include max-width;
        margin-bottom: 2rem;
      }



      &--field{
        margin-top: 2rem;
        mat-label{
          font-family: PoppingSemiBold, sans-serif;
          color: $color-primary;
          font-size: .9rem;
        }

        mat-form-field {
          width: 100%;
          height: 10px;
          font-family: PoppingLight, sans-serif;


          .mat-form-field-appearance-outline .mat-form-field-outline {
            color: $color-secondary !important;
          }
          .mat-mdc-text-field-wrapper{
            height: 40px !important;
          }
          .mat-mdc-form-field-infix{
            max-height: 20px !important;
            padding-top: 5px !important;
            padding-bottom: 5px !important;
          }
          .mat-mdc-form-field-flex{
            height: 40px !important;
          }

          .mat-mdc-option{
            font-size: 10px !important;
          }


        }
        &--option{
          font-family: PoppingLight, sans-serif;
          font-size: .9rem;
        }
      }

      &--actions {
        width: 100%;
        @include align-items-end;

        button {
          font-family: PoppingSemiBold, sans-serif;
          font-size: .7rem;
          margin: 2rem .5rem;
          color: $color-sweet;

          &:first-child {
            color: $color-primary;
          }

          width: 130px;
          height: 25px;
          @include media(desktop-m) {
            width: 150px;
            height: 30px;
            font-size: .9rem;
          }
        }

      }


    }
  }


}

.mat-table {
  border: 1px solid red;
}

.table-container {
  max-width: 1200px;
  margin: 0 2rem;
  @media only screen and (min-width: 1300px) {
    @include max-width;
  }
}

.paginator-container {
  max-width: 1200px;
  @media only screen and (min-width: 1300px) {
    @include max-width;
  }
}

.mdc-data-table__cell {
  padding: 0 !important;
  border: solid 1px #f5f5f5;
  text-align: center !important;
  font-size: .8rem;
  font-family: PoppingSemiBold, sans-serif;
}

.mdc-data-table__header-cell {
  padding: 0 !important;
  margin: 0 1rem;
  background: $color-primary !important;
  color: $color-sweet;
  font-size: .8rem;
  font-family: PoppingBold, sans-serif;
  text-align: center;
  box-shadow: 0 3px 6px #00000029;
  border-radius: 5px;
  border: solid 1px;


}

.mat-sort-header-container {
  justify-content: center !important;
}

.mat-sort-header-arrow {
  color: $color-secondary;
}


@mixin warningDetail($headerWidth, $valueWidth, $headerBackground) {
  &__header {

    @include align-items-center;

    h3 {
      max-width: $headerWidth;
      @include align-items-center;
      margin: .1rem;
      height: 32px;
      background: $headerBackground;
      color: $color-sweet;
      font-size: .8rem;
      font-family: PoppingBold, sans-serif;
      text-align: center;
      box-shadow: 0 3px 6px white;
      border-radius: 5px;
      border: solid 1px $headerBackground;

    }

  }
  &__value {
    @include align-items-center;

    p {
      max-width: $valueWidth;
      @include align-items-center;
      margin: .1rem;
      height: 32px;
      background: #E3F2FF;
      color: $font-color;
      font-size: .8rem;
      font-family: PoppingSemiBold, sans-serif;
      text-align: center;
      box-shadow: 0 3px 6px white;
      border-radius: 5px;
      border: solid 1px #E3F2FF;
    }
  }

  .approver-cell {
    margin: 0 !important;
    padding: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .approver-list {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .approver-item {
    margin: 0 !important;
    padding: 0 !important;
    text-align: center !important;
  }
}
