@import "src/styles/globals";
@import "src/styles/breakpoints";


.list-warehouse-order-preparation-container{
  width: 90%;
  margin: 5rem 0;

  @include media(desktop-m){
    margin: 5rem 2.5rem;
    max-width: 1400px;
  }


  @include media(desktop-l){
    margin: 5rem auto;
    max-width: 1600px;
  }

  &--title{
    border: solid 1px;
    width: 100%;
    @include align-items-center;
    background: $color-secondary;
    font-family: PoppingSemiBold, sans-serif;
    color: $color-sweet;
    border-radius: 8px;
    height: 48px;
    &:hover{
      background: $color-primary;
      cursor: pointer;
    }
    span{
      font-size: 1rem;
      text-align: start;
      margin-left: 2rem;
      @include align-items-center;
    }
    h2{
      width: 600px;
      margin: 0 auto;
      font-size: 1.5rem;

      @include media(desktop-m){
        width: 700px;
        font-size: 2rem;
      }
    }
  }

  &--card{
    width: 100%;
    border-radius: 32px;
    margin: .5rem auto;


    mat-card-content{
      font-size: 14px;
      padding: 0 16px !important;
    }

    &__form{
      width: 100%;

      &--field{
        margin-top: 2rem;
        mat-label{
          font-family: PoppingSemiBold, sans-serif;
          color: $color-primary;
          font-size: .9rem;
        }

        mat-form-field {
          width: 100%;
          height: 10px;
          font-family: PoppingLight, sans-serif;


          .mat-form-field-appearance-outline .mat-form-field-outline {
            color: $color-secondary !important;
          }
          .mat-mdc-text-field-wrapper{
            height: 40px !important;
          }
          .mat-mdc-form-field-infix{
            max-height: 20px !important;
            padding-top: 5px !important;
            padding-bottom: 5px !important;
          }
          .mat-mdc-form-field-flex{
            height: 40px !important;
          }

          .mat-mdc-option{
            font-size: 10px !important;
          }


        }
        &--option{
          font-family: PoppingLight, sans-serif;
          font-size: .9rem;
        }
      }
      &--actions{
        width: 100%;
        @include align-items-end;
        button{
          font-family: PoppingSemiBold, sans-serif;
          font-size: .7rem;
          margin: 2rem .5rem;
          color: $color-sweet;

          &:first-child{
            color: $color-primary;
          }
          width: 130px;
          height: 25px;
          @include media(desktop-m){
            width: 150px;
            height: 30px;
            font-size: .9rem;
          }
        }

      }


    }


  }

  &--stats{
    width: 100%;
    margin: 0 auto;
    border-radius: 32px;

    mat-card-content{
      @include align-items-center;
    }

    div{
      text-align: center;

      h1{
        font-size: 1.5rem;
        font-family: PoppingSemiBold, sans-serif;
      }
      p{
        font-family: PoppingLight, sans-serif;
      }
      color: $font-primary;
    }
  }
}


.warehouse-table-container {
  width: 90%;
  margin: 0;

  @include media(desktop-l){
    width: 100%;
    margin: 0 auto;
  }
}
.mat-table {
  border: 1px solid red;
}


.mdc-data-table__cell{
  padding: 0 !important;
  border: solid 1px #f5f5f5;
  text-align: center !important;
  font-size: .8rem;
  font-family: PoppingSemiBold, sans-serif;
}
.mdc-data-table__header-cell{
  padding: 0 !important;
  margin: 0 1rem;
  background: $color-primary !important;
  color: $color-sweet;
  font-size: .8rem;
  font-family: PoppingBold, sans-serif;
  text-align: center;
  box-shadow: 0 3px 6px #00000029;
  border-radius: 5px;
  border: solid 1px;


}
.form-field-value {
  width: 100px;
  display: block !important;

  @include media(desktop-l) {
    width: 200px;
  }

  &:has(.mat-form-field-wrapper) {
    ::ng-deep .mat-form-field-wrapper {
      padding: 0 !important;
      height: 50px;
    }
  }
  &::ng-deep .mat-mdc-text-field-wrapper {
    height: 50px !important;
    font-size: .8rem !important;
  }
  &::ng-deep .mat-mdc-form-field-subscript-wrapper{
    display: none;
  }

  &:first-child {
    padding-bottom: 0 !important;
  }
}

/*.cdk-overlay-pane{
  width: 250px !important;
}*/

.mat-sort-header-container{
  justify-content: center !important;
}
.mat-sort-header-arrow{
  color: $color-secondary;
}
mat-icon{
  height: 30px !important;
}

.column-large-text {
  display: inline;
}
.column-short-text {
  display: none;
}
@media screen and (max-width: 1280px) {
  .column-large-text {
    display: none;
  }
  .column-short-text {
    display: inline;
  }

}
