@import "breakpoints";
@import "globals";

$color-for-border-test: #000000;
$border-for-test: solid 1px $color-for-border-test;

.menu-toggle {
  background-color: $color-primary;
  border: none !important;

  @include media(desktop-s) {
    display: none !important;
  }
}
.example-spacer {
  flex: 1 1 auto;
}


.mat-toolbar-multiple-rows {
  width: 70%;
  @include media(desktop-s) {
    width: 85px !important;
  }
  background-color: $color-primary;

}

mat-toolbar-row a.active {
  background-color: $color-primary;
}

.mat-sidenav {
  position: fixed;
  height: 100%;

  transition: ease-in-out width .6s;
  width: 50px !important;
  @include media(desktop-s){
    width: 60px !important;
  }
  overflow: visible ;
  opacity: 1;

  .menu-header{
    margin-top: 5rem;
    .menu{


      &__item--icon1{
        margin: 0 auto;
        width: 33px;
        height: 35px;
      }

      &__item--text{
        display: none;
      }
    }

  }

  background-color: $color-primary !important;

  &:hover {
    @include transition;

    width: 300px !important;

    .menu-header {
      margin-top: 5rem;
      .menu {
        &__item--icon1 {
          margin: 1rem;
        }

        &__item--text {
          @include transition;
          display: flex;
        }
      }
    }

  }

  &:not(:hover){
    .menu-header {
      .menu {
        &__submenu{
          display: none;
          &__item{
            display: none;
          }
          &__second-menu{
            display: none;
          }
        }
      }
    }
  }

}


mat-sidenav-content {
  width: 100%;
  margin-left: 50px !important;
  background: $color-sweet;
  @include align-items-center;
}
.internal-header-container{
  background: $color-sweet;
  @include max-width;
  overflow: hidden;

  @include media(desktop-s){
    border: solid 1px #fff;
  }
}
.router {
  width: 100%;
  background: $color-sweet;
  overflow-x: hidden; /* Remove horizontal scroll */

  @include media(desktop-s){
    border: solid 1px #fff;
  }
}
.header-user{
  @include align-items-end;
  margin: 1.5rem;
  display: inline-flex;

  span{
    margin:  .5rem 0;
    font-size: 1.1rem;
    font-family: PoppingLight, serif;
    color: $color-sweet;
  }
  button{
    @include align-items-center;
  }
}
#sidenavContainer {
  width: 100%;

}

