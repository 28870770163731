@import "../styles/globals";
@import "../styles/breakpoints";

.footer {
  @include max-width;
  div{
    width: 100%;
  }
  &__main-title{
    font-size: 1.8rem;
    font-family: PoppingBold, serif;
    color: $color-secondary;
  }
  &__sub-title{
    font-size: 1rem;
    font-family: PoppingSemiBold, serif;
    color: #25395c;
  }
  &__copyright{
    font-size: .7rem;
    font-family: PoppingSemiBold, serif;
    color: #87919c;
  }
  bottom: 0;
}

.social-network {
  &:hover {

    img {
      border-radius: 50%;
      background-color: #333333;
    }
  }
}
