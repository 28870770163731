@import "breakpoints";
@import "globals";

.popup-container{
  @include align-items-center;
  height: auto;
  background: #FFFFFF 0 0 no-repeat padding-box;
  opacity: 1;
  &--image{
    margin: 0 2rem;
  }
  &--title{
    font-size: 2rem;
    font-family: PoppingBold, sans-serif;
    color: $color-secondary;
    text-align: left;
    margin: 1rem 0;
  }
  &--text{
    font-size: 1.2rem;
    font-family: PoppingLight, sans-serif;
    text-align: left;
  }
  &--actions{
    @include align-items-end;
    &__button{
      width: 140px;
      background: $color-primary;
      text-align: center;
      font-size: 1.2rem;
      font-family: PoppingBold, sans-serif;
      color: $color-sweet;
      margin: 0 5rem;
    }


  }
}


.swal2-popup {
  border-radius: 30px !important;
  overflow: hidden;
}
.swal2-actions{
  width: 100% !important;
  /*align-items: flex-end !important;
  justify-content: flex-end !important;*/
}
.mat-mdc-dialog-container {
  --mdc-dialog-container-shape: 0px !important;

}
