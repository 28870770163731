
@font-face {
  font-family: PoppingBold;
  src: url('../assets/fonts/Poppins-Bold.ttf');
  font-weight: bold;
}
@font-face {
  font-family: PoppingLight;
  src: url('../assets/fonts/Poppins-Light.ttf');
  font-weight: bold;
}
@font-face {
  font-family: PoppingSemiBold;
  src: url('../assets/fonts/Poppins-SemiBold.ttf');
  font-weight: bold;
}

$color-primary: #005eac;
$color-secondary: #29aae3;
$color-third: #333333;
$color-sweet: #ffffff;
$color-gray: #999999;
$color-hover: #1f4466;



$success-color: #7ac243;
$font-primary: 'Poppins', sans-serif;
$font-color: #3c3c3b;

/** This variable is used to change the carousel control on compilation time,
    but you can override the value with custom color **/
$carousel-control-color: #b5b5b4;

$assets-folder: '../../../../../../assets/';



@mixin button-action($width, $height, $background-color, $box-shadow) {
  width: $width;
  height: $height;
  text-align: center;
  border: solid 1px $background-color;
  border-radius: 10px;
  font-family: $font-primary;
  font-size: 0.78rem;
  font-weight: 300;
  background-color: $background-color;
  color: $color-sweet;
  cursor: pointer;
  @if ($box-shadow) {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }

}

@mixin align-items-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin align-items-end {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

@mixin align-items-start {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

@mixin border-solid-for-test($color,$size) {
  border: solid $size $color;
}

@mixin max-width {
  max-width: 1500px;
  margin: 0 auto;
}
@mixin min-height{
  min-height: 650px;
}

@mixin mat-card-border-radius($value) {
  .mat-card {
    @if $value == top {
      border-radius: 10px 10px 0 0;
    } @else if $value == bottom {
      border-radius: 0 0 10px 10px;
    } @else {
      border-radius: $value;
    }
  }
}

.timepicker-overlay {
  z-index: 10000000 !important;
}

.timepicker-backdrop-overlay {
  z-index: 10000000 !important;
}


@mixin menu{

  .menu{

    &__item{
      display: flex;
      align-items: center;
      padding: .5rem 0;
      width: 100%;
      height: 80px;

      &:hover {
        background-color: $color-hover;
        cursor: pointer;


      }


      &--text{
        text-align: center;
        color: $color-sweet;
        font-weight: bold;
        font-family: normal normal bold 16px/13px Poppins, serif;
      }

    }

    &__submenu{
      list-style: none;
      padding: 0;
      display: none;
      &__item{
        width: 100%;
        height: 52px;
        margin: 0;
        padding-left: 30px;
        display: flex;
        align-items: center;

        &--icon{
          width: 30px;
          height: 30px;
          margin: 0 1rem;
        }
        &--text{
          text-align: justify-all;
          color: $color-sweet;
          font-weight: bold;
          font-family: normal normal bold 16px/13px Poppins, serif;
        }
        &:hover {
          background-color: $color-hover;
          cursor: pointer;
        }

      }
      &__second-menu{
        width: 80%;
        padding-left: 50px;

        ul{
          display: none;
          list-style: disc;
          li{
            text-align: justify-all;
            color: $color-sweet;
            font-weight: bold;
            font-family: normal normal bold 16px/13px Poppins, serif;
            &:hover {
              background-color: $color-hover;
              cursor: pointer;
            }
          }
        }


      }

    }
  }

}


@mixin list-style{
  width: 100%;
  margin: 0 auto;
  cursor: pointer;
  span{
    text-align: justify-all;
    color: $color-sweet;
    font-size: 1.1rem;
    font-family: PoppingLight, serif;
  }

}

@mixin transition{
  transition: ease-in-out width .6s;
}


@mixin close_button{
  .close_button{
    @include align-items-end;
    height: 50px;
    mat-icon{
      height: 30px !important;
    }
  }

}
.mat-sort-header-container{
  justify-content: center !important;
}
.mat-sort-header-arrow{
  color: white;
}
